<template>
	<!-- 监理收文 -->
  <div class="mod-config">
	  <el-card body-style="padding-bottom: 0;">
		<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
			<el-row>
				<el-col :span="20">
					<el-form-item label="文件类型">
						<el-select class="selItemInput" v-model="fileKind2" placeholder="请选择" clearable>
							<el-option v-for="item in receiptTypeList" :key="item.displayValue" :label="item.displayName"
									   :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="收文时间">
						<el-date-picker
								class="selItemInput"
								v-model="dispTime"
								type="daterange"
								value-format="yyyy-MM-dd"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="4"  class="selBtnGroup">
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="resetFormData()">重置</el-button>
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="selGetDataList()" v-if="showSearch">检索</el-button>
				</el-col>
			</el-row>
		</el-form>
	  </el-card>
	  <el-row style="margin-top: 20px;">
	  	<el-col :span="pageSpan">
	  		<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-circle-plus-outline" @click="addOrUpdateHandle()" v-if="showAdd">收文登记</el-button>
			<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-folder" @click="fileTypeSetingHandle()" v-if="showFileKindSet">文件类型设定</el-button>
		</el-col>
	  	<el-col :span="pageSpan" style="text-align: right;">
            <el-pagination
                    background
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    layout="prev, pager, next"
                    :pager-count="5"
                    :current-page="pageIndex"
                    :page-size="pageSize"
                    :total="totalPage">
            </el-pagination>
	  	</el-col>
	  </el-row>
	  <div :style="siteContentViewHeight()">
		  <el-card
			style="margin-top: 20px; height: 100%;"
			body-style="height: 100%; padding: 0;">
			  <el-row style="height: 100%;">
<!--				  <el-col :span="2" style="background-color: #e9f4fb; height: 100%;">-->
<!--					  <el-menu-->
<!--							  :default-active="tabIndex"-->
<!--							  class="tabMenu"-->
<!--							  style="margin-top: 50px;">-->
<!--						  <el-menu-item :index="item.displayValue" @click="receiptType(item.displayValue)" style="text-align: center;" v-for="item in receiptTypeList">-->
<!--							  {{item.displayName}}-->
<!--						  </el-menu-item>-->
<!--					  </el-menu>-->
<!--				  </el-col>-->
				  <el-col :span="24" style="height: 100%;">
					  <el-table
							  class="dataListTable"
							  :data="dataList"
							  header-align="center"
							  height="100%"
							  style="width: 100%;"
							  :row-class-name="tableRowClassName"
							  header-cell-class-name="dataListTHeader"
							  >
                          <el-table-column
                                  type="index"
                                  width="60"
                                  align="center">
                          </el-table-column>
						  <el-table-column
								  label="发文类型"
								  width="120"
								  align="center">
							  <template slot-scope="scope">
								  {{getDocumentType(scope.row.fileKind2)}}
							  </template>
						  </el-table-column>
						  <el-table-column
								  prop="fileNo"
								  label="收文编号"
								  width="140"
								  align="center">
						  </el-table-column>
						  <el-table-column
								  prop="fileTitle"
								  label="收文主题"
								  align="left">
						  </el-table-column>
						  <el-table-column
								  prop="fileNumber"
								  label="文件数量"
								  width="120"
								  align="center">
						  </el-table-column>
						  <el-table-column
								  prop="receDispCompany"
								  label="发文单位"
								  width="240"
								  align="center">
						  </el-table-column>
						  <el-table-column
								  prop="receDispTime"
								  label="发文时间"
								  width="120"
								  align="center">
						  </el-table-column>
						  <el-table-column
								  prop="receTime"
								  label="收文时间 "
								  width="120"
								  align="center">
						  </el-table-column>
						  <el-table-column
								  prop="exaFlgName"
								  label="状态 "
								  width="120"
								  align="center">
							  <template slot-scope="scope">
								  <span v-if="scope.row.exaFlg==='1'" style="color: #e7b243;">{{scope.row.exaFlgName}}</span>
								  <span v-if="scope.row.exaFlg!=='1'">{{scope.row.exaFlgName}}</span>
							  </template>
						  </el-table-column>
						  <el-table-column
								  label="操作"
								  width="250"
								  v-if="showDownload || showCheck || showEdit || showDelete"
								  align="center">
							  <template slot-scope="scope">
								  <el-button v-preventReClick type="warning" size="small" @click="auditeHandle(scope.row.fileId)" v-if="showCheck && scope.row.exaFlg !=='1'">审核</el-button>
<!--								  <el-button v-preventReClick type="warning" size="small" @click="archiveFile(scope.row.fileId)" v-if="showArchive && scope.row.exaFlg ==='1' && scope.row.archiveFlag ==='0'">归档</el-button>-->
								  <el-button v-preventReClick type="primary" size="small" @click="addOrUpdateHandle(scope.row.fileId)" v-if="showEdit && scope.row.exaFlg !=='1'">修改</el-button>
								  <el-button v-preventReClick type="danger" size="small" @click="deleteHandle(scope.row.fileId)" v-if="showDelete && scope.row.exaFlg !=='1'">删除</el-button>
								  <el-button v-preventReClick type="primary" size="small" @click="selectFile(scope.row.fileUri)" v-if="showDownload && scope.row.fileUri">下载</el-button>
<!--								  <el-button v-preventReClick type="danger" size="small" @click="removeArchiveFile(scope.row.fileId)" v-if="showArchive && scope.row.exaFlg ==='1' && scope.row.archiveFlag ==='1'">取消归档</el-button>-->
							  </template>
						  </el-table-column>
					  </el-table>
				  </el-col>
			  </el-row>
		  </el-card>
	  </div>
	  <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="dataList"></add-or-update>
	  <file-type-seting v-if="fileTypeSetingVisible" ref="fileTypeSeting" @refreshDataList="getDocumentTypeList()"></file-type-seting>
	  <audit v-if="auditVisible" ref="audit" @refreshDataList="dataList"></audit>
  </div>
</template>
<script>
import AddOrUpdate from './receipt-add-or-update'
import FileTypeSeting from './../working/file-type-seting'
import $common from "@/utils/common.js"

import audit from './superdocument-audit'
export default {
  data() {
    return {
		showSearch: false,
		showFileKindSet: false,
		showAdd: false,
		showEdit: false,
		showDelete: false,
		showCheck: false,
		showArchive: false,
		showDownload: false,
		pageSpan: 12,
      tabIndex: '',
	  fileTypeSetingVisible: false,
		auditVisible:false,
	  addOrUpdateVisible: false,
	  receiptTypeList: [],
	  form:{},
	  value:"",
	  value1:"",
        fileKind2: "",
        fileNo: "",
        fileTitle: "",
		exaFlg: "",
        dispMan: "",
        dispTime: [],
        deceDispCompany: "",
		exaFlgName: "",
        answerFinishFlg: "",
        answerFinishFlgName: "",
	  options: [
		  {
			  value:"1",
			  label:"2"
		  }
	  ],
          dataList: [],
          //当前页码
          pageIndex: 1,
          //当前记录数
          pageSize: 10,
          //总页数
          totalPage: 0,
	  tableSelVal:[]
    };
  },
  components: {
	AddOrUpdate,
	  audit,
	FileTypeSeting
  },
	mounted() {
		this.showSearch = $common.isAuth('receipt:search');
		this.showFileKindSet = $common.isAuth('receipt:file:kind:set');
		this.showAdd = $common.isAuth('receipt:add');
		this.showEdit = $common.isAuth('receipt:edit');
		this.showDelete = $common.isAuth('receipt:delete');
		this.showCheck = $common.isAuth('receipt:check');
		this.showArchive = $common.isAuth('receipt:archive');
		this.showDownload = $common.isAuth('receipt:download');
		if (!this.showFileKindSet && !this.showAdd){
			this.pageSpan = 24;
		}
		this.getSubjectDropDown();
		this.getDataList();
	},
  methods: {
	  selectFile(url) {
	  	window.open(url);
	  },
	  getDocumentType(value){
		  let name ="";
		  this.receiptTypeList.forEach(function(item){
			  if (value===item.displayValue){
				  name = item.displayName;
			  }
		  });
		  return name;
	  },
	  //删除
	  deleteHandle(id){
		  this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
		  }).then(() => {
			  this.$http({
				  url:  this.$store.state.httpUrl + "/business/fileinfo/deleteSend",
				  method: "POST",
				  data: id,
			  }).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					  this.$message({
						  message: '操作成功',
						  type: 'success',
						  duration: 1500,
						  onClose: () => {
							  this.getDataList()
						  }
					  })
				  } else {
					  this.$message.error(data.msg)
				  }
			  });
		  })
	  },
	  // 审核
	  auditeHandle(id) {
		  this.auditVisible = true
		  this.$nextTick(() => {
			  this.$refs.audit.init(id,1)
		  })
	  },
	  archiveFile(id){
		  this.$confirm(`确定对选中的发文进行归档操作?`, '提示', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
		  }).then(() => {
			  this.$http({
				  url:  this.$store.state.httpUrl + "/business/fileinfo/archiveFile/"+id,
				  method: "get",
				  data: id,
			  }).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					  this.$message({
						  message: '操作成功',
						  type: 'success',
						  duration: 1500,
						  onClose: () => {
							  this.getDataList()
						  }
					  })
				  } else {
					  this.$message.error("归档设置失败")
				  }
			  });
		  });
	  },
	  removeArchiveFile(id){
		  this.$confirm(`确定对选中的发文进行取消归档操作?`, '提示', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
		  }).then(() => {
			  this.$http({
				  url:  this.$store.state.httpUrl + "/business/fileinfo/removeArchiveFile/"+id,
				  method: "get",
				  data: id,
			  }).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					  this.$message({
						  message: '操作成功',
						  type: 'success',
						  duration: 1500,
						  onClose: () => {
							  this.getDataList()
						  }
					  })
				  } else {
					  this.$message.error("取消归档失败")
				  }
			  });
		  });
	  },
	  // 获取数据列表
	  getDataList() {
	  	if (this.showSearch ) {
			this.$http({
				url: this.$store.state.httpUrl + "/business/fileinfo/queryRece",
				method: 'get',
				params: this.$http.adornParams({
					page: this.pageIndex,
					fileKind2: this.fileKind2,
					limit: this.pageSize,
					dispTimeBegin: this.dispTime[0] ? this.dispTime[0] : "",
					dispTimeEnd: this.dispTime[1] ? this.dispTime[1] : "",
					exaFlg: this.exaFlg,
					workNo: $common.getItem("workNo"),
					subjectNo: $common.getItem("subjectNo"),
				})
			}).then(({data}) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
					this.totalPage = data.body.totalCount;
				} else {
					this.dataList = []
					this.totalPage = 0
				}
			})
		}
	  },
      // 每页数
      sizeChangeHandle(val) {
          this.pageSize = val
          this.pageIndex = 1
          this.getDataList()
      },
      // 当前页
      currentChangeHandle(val) {
          this.pageIndex = val
          this.getDataList()
      },
	  //搜索
	  selGetDataList(){
		  this.pageIndex = 1;
		  this.getDataList();
	  },
	  fileTypeSetingHandle(){
	  	this.fileTypeSetingVisible = true
	  	this.$nextTick(() => {
	  	  this.$refs.fileTypeSeting.init('0')
	  	})
	  },
	  //收文类型
	  receiptType(value){
		  this.fileKind2 = value;
		  this.pageIndex = 1;
		  this.getDataList();
	  },
	  //获取收文类型
	  getSubjectDropDown() {
		  this.$http({
			  url:  this.$store.state.httpUrl + "/common/getReceiptType/"+$common.getItem("workNo")+"/"+$common.getItem("subjectNo"),
			  method: "get",
			  params: {},
		  }).then(({data}) => {
			  if (data && data.resultCode === 200) {
				  this.receiptTypeList = data.body;
				  // this.tabIndex = this.receiptTypeList[0].displayValue;
				  // this.fileKind2 = this.receiptTypeList[0].displayValue;
				  // this.getDataList();
			  }
		  })
	  },
	  //重置
	  resetFormData(){
		  this.dispTime = [];
		  this.fileKind2 = '';
		  this.pageIndex = 1;
		  this.getDataList();
	  },
	  siteContentViewHeight () {
	  var height = this.$store.state.common.documentClientHeight - 260;
	  return { height: height + 'px' }
	},
	handleSelectionChange(val) {
		this.tableSelVal = val;
	},
	tableRowClassName({ row, rowIndex }) {
	  let color = ''
	  for(let item of this.tableSelVal){
		if(item.id === row.id)color = 'table-SelectedRow-bgcolor'
	  }
	  return color
	},
	  archiveFile(id){
		  this.$http({
			  url:  this.$store.state.httpUrl + "/business/fileinfo/archiveFile/"+id,
			  method: "get",
			  params: {},
		  }).then(({data}) => {
			  if (data && data.resultCode === 200) {
				  this.$message.info("归档设置成功");
			  } else {
				  this.$message.warning("归档设置失败");
			  }
		  })
	  },
	  uploadFile(param){
		  if (!this.dataForm.fileKind2){
			  this.$message.info('请选择文件类型')
			  return;
		  }
		  const formData = new FormData();
		  formData.append('file', param.file);
		  this.$refs.uploadFile.clearFiles();
		  this.$http.post(
				  this.$store.state.httpUrl + "/file/upload/"+$common.getItem("workNo")+"/20/"+this.dataForm.fileKind2,
				  formData,
				  {
					  headers: { 'Content-Type': 'multipart/form-data' }
				  },
		  ).then(res => {
			  res = res.data
			  if (res.resultCode === 200){
				  this.dataForm.filePath = res.body.fileSavePath;
				  this.fileList = [{
					  fileName: res.body.fileName,
					  filePath: res.body.fileSavePath,
					  fileUri: res.body.fileUri
				  }];
			  } else {
				  this.$message.info('文件上传失败')
			  }
		  })
	  },
	addOrUpdateHandle(id){
		this.addOrUpdateVisible = true
		this.$nextTick(() => {
		  this.$refs.addOrUpdate.init(id)
		})
	}
  }
};
</script>
<style lang="scss" scoped="scoped">
	.selItemText{
		font-size: 14px;
		color: #1c1c1c;
		display: inline-block;
		width: 80px;
	}
</style>
